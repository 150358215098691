<template>
  <div>
    <!--   <router-view v-slot="{ Component }">
    <transition
      :enter-active-class="`animate__animated animate__${$route.meta.enterTransition}`"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <component :is="Component" :key="$route.path"></component>
    </transition>
    </router-view>-->
    <router-view></router-view>
    <widget-container-modal />
  </div>
</template>

<script>

import { container } from "jenesius-vue-modal";

export default {
  name: "App",
  computed: {
    token() {
      return this.$store.getters.token;
    },
  },
  components: {
    WidgetContainerModal: container,
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~animate.css/animate.min.css";

@font-face {
  font-family: "Victor_Mono";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("Victor_Mono"), url("/fonts/Victor_Mono/VictorMono-VariableFont_wght.ttf") format("woff2"), url("/fonts/Roboto/Roboto-Regular.woff") format("woff");
}

body{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  line-height:200%
}
.card-text{
  font-size: 1.0rem;  

}
h2{
  font-size: 1.5rem;
}
h3{
  font-size: 1rem;
}
.space-between {
  justify-content: space-between;
}
.row{
  margin: 0;
}
.bgFoto {
  background-image: url(./assets/startup.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  font: 80% Victor_Mono,Verdana, Arial, Helvetica, sans-serif;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;  

  background-size: 150% 150%;
	animation: gradient 40s ease infinite;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.bgmain2 {
  background: url(./assets/main2.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  font: 80% Victor_Mono,Verdana, Arial, Helvetica, sans-serif;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;  
}
.cardPic{
  width: 90%;
  
  top: 50%;
  left: 50%;
}
.cardCenter {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.headline{
  color:white;
  padding-top: 8%;
  font-size: 95px;
  font-weight: bolder;
  font-size: clamp(30px, 4.0vw,  95px);
}
.psText{
  color:rgb(111, 137, 253);
  font-weight: bolder;
}
.header_Justify {
  min-width: 100%;
  justify-content: space-around;
}
.header{
  position: sticky;
 
		top: 0;
  z-index: 1000;
}
.Abschnitt-titel{
  padding-top: 50px;
  
  font-size: clamp(2.5em, 5.0vw,  7em);
    color: rgb(255, 255, 255);
    
    font-weight: bolder;
}
.Abschnitt-titel2{
  padding-top: 50px;
  font-size: clamp(2.5em, 5.0vw,  7em);
    color: rgb(0, 0, 0);
    
    font-weight: bolder;
}
.Abschnitt-titel2-Handy{
  padding-top: 50px;
  font-size: 2em;

    color: rgb(0, 0, 0);
    
    font-weight: bolder;
}
.cardHeader{
  font-size: 3em;
    color: rgb(66, 66, 66);
  
    font-weight: bolder;
}
.nav {
  background-color: rgb(255, 255, 255);
  color: black !important;
}
.navbar {
  min-width: 100%;
}
.nav-item {
  justify-content: center !important;
  text-align: center;
}
.headerIcon {
  font-size: 36px;
  color: black;
}

.t-center {
  text-align: center;
}
.justifyCenter {
  justify-content: center !important;
}
.DES {
  margin-top: 50px;
  color: white;  
  text-align: center;
}
hr { 
  display: block;
    height: 10px;
    border: 0;
    border-top: 12px solid #ff2626;
    border-radius: 50px;
    opacity: 1;
    
    
}
.hellblau{
  background-color: #C0CFF6;
  font-weight: bolder;
  /* margin: 50px 0px 50px 50px;  */
  padding: 20px;
}
.hintergrundSchwarz{
  color: white;
  background-color: rgb(44, 44, 44);
}
.hintergrundHell{
  color: black;
  background-color: rgb(255, 255, 255);
}
.bg-white {
  color: black;
  background-color: white;
}
.bg-white li {
  padding-bottom: 20px;
}
.bg1 {
  color: white;
  background: url(./assets/Header1.jpg);
  background-repeat: no-repeat;
  background-size: auto;
}
.bg1 li {
  padding-bottom: 20px;
}
.bg-grau{
  background-color: #c5c5c5;
  width: 100%;
}
.pillarsoft{
  padding-top: 10px;
  width:90%;
}
.bildDiv{
  width:100%;
}
#stickybar {
  right: 0;
  position: fixed;
  top: 300px;
  z-index: 10000;
  visibility: visible;
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  animation-delay: 2s;
}
#stickybar ul li {
  list-style-type: none;
}
#stickybar a {
  background: #ff7200;
  color: #fff;
  display: block;
  width: 55px;
  height: 55px;
  font: 1.4em;
  line-height: 55px;
  position: relative;
  text-align: center;
  margin-bottom: 2px;
}
#stickybar a span {
  line-height: 55px;
  background: #ff7200;
  
  position: absolute;
  text-align: center;
  width: 180px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
}
.stickybarRightOff{
  right: -180px;
  }
.stickybarRightOn{
  right: -180px;
  }
#stickybar a:hover span {
  right: 100%;
}
#stickybar a[class*="phone"],
#stickybar a[class*="phone"]:hover,
#stickybar a[class*="phone"] span {
  background: #ff7200;
}
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.einblenden1{  
  animation: einblenden 4.5s;
    -moz-animation: einblenden 4.5s; /* Für Firefox */
    -webkit-animation: einblenden 4.5s; /* Für Safari und Chrome */
    -o-animation: einblenden 4.5s; /* Für Opera */
}
.titelBorder{
  border: 2px solid rgb(29, 29, 102);
  border-radius: 15px;
  
  margin: 25px;
}
.ele3_1 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  visibility: hidden;
  
}
.ele3_2 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 2s;
  visibility: hidden;
}
.ele3_3 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 3s;
  visibility: hidden;
}
.ele3_4 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 4s;
  visibility: hidden;
}
.ele3_5 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 5s;
  visibility: hidden;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1550px;
  }
}

@media screen and (min-width: 990px) {
 /* Handyansicht*/
.handyansicht, .keinGross{
  display: none;
  visibility: hidden;
}
.LinkIcon{
  font-size: 50px;
  color: black;
 padding-right: 30px;
}
.Pillar{
 color: rgb(29, 29, 102);
  font-weight: bolder;
  font-size: 50px;
}
.Soft{
   color: black;
  font-weight: bolder;
  font-size: 50px;
}
.whi {
  color: rgb(29, 29, 102);
  font-weight: bolder;
  font-size: 1rem;
}

}

@media screen and (max-width: 990px) {
  /*  Grossansicht*/
.bildschirnansicht, .keinKlein{
  display: none;
  visibility: hidden;
}
.handytext{
  font-size: 0.8rem;
}
.headerIcon {
  font-size: 20px;
  color: black;
}
 .LinkIcon{
  font-size: 30px;
  color: black;
 padding-right: 30px;
}
.Pillar{
 color: rgb(29, 29, 102);
  font-weight: bolder;
  font-size: 30px;
}
.Soft{
   color: black;
  font-weight: bolder;
  font-size: 30px;
}
.whi {
  color: rgb(29, 29, 102);
  font-weight: bolder;
  font-size: 1rem;
}
}

@keyframes einblenden {
    from { opacity:0; }
    to { opacity:1; }
}

@-moz-keyframes einblenden { /* Für Firefox */
    from { opacity:0; }
    to { opacity:1; }
}

@-webkit-keyframes einblenden { /* Für Safari und Chrome */
    from { opacity:0; }
    to { opacity:1; }
}

@-o-keyframes einblenden { /* Für Opera */
    from { opacity:0; }
    to { opacity:1; }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }

}


/*  Kontaktformular */



*:focus {
  outline: none;
}



.form_hint,
.required_notification {
  font-size: 11px;
}
.contact_form ul {
  list-style-type: none;
  list-style-position: outside;
  margin: 0px;
  padding: 0px;
}
.contact_form li {
  padding: 12px;
  border-bottom: 1px solid #eee;
  position: relative;
}

.contact_form h2 {
  margin: 0;
  display: inline;
}
.required_notification {
  color: #d45252;
  margin: 5px 0 0 0;
  display: inline;
  float: right;
}
.contact_form label {
  margin-top: 3px;
  display: inline-block;
  padding: 3px;
}
.textLabel {
  width: 150px;
}
.formtextField {
  height: 20px;
  width: 220px;
  padding: 5px 8px;
}
.checkbox {
  width: 20px !important;
}
.checkboxKlein {
  font-size: small;
}
.checkboxLabel {
  width: 355px !important;
}
.checkboxLabelKlein{
  font-size: small;
}
.contact_form textarea {
  padding: 8px;
  width: 450px;
}
.contact_form button {
  margin-left: 156px;
}
.contact_form input,
.contact_form textarea {
  border: 1px solid #aaa;
  /* box-shadow: 0px 0px 3px #ccc, 0 10px 15px #eee inset;
  border-radius: 2px;*/
}
.contact_form input:focus,
.contact_form textarea:focus {
  background: #fff;
  border: 1px solid #555;
  box-shadow: 0 0 3px #aaa;
}
/* Button Style */
button.submit {
  background-color: #68b12f;
  background: -webkit-linear-gradient(top, #68b12f, #50911e);
  background: -moz-linear-gradient(top, #68b12f, #50911e);
  background: -ms-linear-gradient(top, #68b12f, #50911e);
  background: -o-linear-gradient(top, #68b12f, #50911e);
  background: linear-gradient(top, #68b12f, #50911e);
  border: 1px solid #509111;
  border-bottom: 1px solid #5b992b;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  box-shadow: inset 0 1px 0 0 #9fd574;
  -webkit-box-shadow: 0 1px 0 0 #9fd574 inset;
  -moz-box-shadow: 0 1px 0 0 #9fd574 inset;
  -ms-box-shadow: 0 1px 0 0 #9fd574 inset;
  -o-box-shadow: 0 1px 0 0 #9fd574 inset;
  color: white;
  font-weight: bold;
  padding: 6px 20px;
  text-align: center;
  text-shadow: 0 -1px 0 #396715;
}
button.submit:hover {
  opacity: 0.85;
  cursor: pointer;
}

button.submit:active {
  border: 1px solid #20911e;
  box-shadow: 0 0 10px 5px #356b0b inset;
  -webkit-box-shadow: 0 0 10px 5px #356b0b inset;
  -moz-box-shadow: 0 0 10px 5px #356b0b inset;
  -ms-box-shadow: 0 0 10px 5px #356b0b inset;
  -o-box-shadow: 0 0 10px 5px #356b0b inset;
}
.contact_form input:focus,
.contact_form textarea:focus {
  /* add this to the already existing style */
  padding-right: 70px;
}
.contact_form input,
.contact_form textarea {
  /* add this to the already existing style */
  -moz-transition: padding 0.25s;
  -webkit-transition: padding 0.25s;
  -o-transition: padding 0.25s;
  transition: padding 0.25s;
}

input:required,
textarea:required {
  background: #fff no-repeat 98% center;
}
::-webkit-validation-bubble-message {
  padding: 1em;
}
.contact_form input:focus:invalid,
.contact_form textarea:focus:invalid {
  /* when a field is considered invalid by the browser */
  background: #fff no-repeat 98% center;
  box-shadow: 0 0 5px #d45252;
  border-color: #b03535;
}
.contact_form input:required:valid,
.contact_form textarea:required:valid {
  /* when a field is considered valid by the browser */
  background: #fff no-repeat 98% center;
  box-shadow: 0 0 5px #5cd053;
  border-color: #28921f;
}
.contact_form input:focus  {
  display: inline;
}
.contact_form input:required:valid {
  background: #28921f;
} /* change form hint color when valid */
.contact_form input:required:valid  {
  color: #28921f;
} /* change form hint arrow color when valid */

</style>
